import { Link } from "gatsby";
import React, { useState } from "react";
import logo from "../images/logo.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  return (
    <header
      className="sticky top-0 z-40 bg-gray-800"
      style={{
        borderBottom: "1px solid rgba(107, 114, 128, .5)",
      }}
    >
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-4">
        <Link to="/">
          <h1 className="flex items-center no-underline">
            <img alt="Logo" src={logo} />
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto `}
        >
          {[
            {
              route: `/`,
              title: `Home`,
            },
            {
              route: `/contact/`,
              title: `Contact`,
            },
            {
              route: `/blog/`,
              title: `Blog`,
            },
          ].map((link) => (
            <Link
              className="font-thin block mt-4 no-underline md:inline-block md:mt-0 md:ml-6 text-gray-100"
              key={link.title}
              to={link.route}
                    activeClassName="border"

            >
              <div className=" border-0 border-gray-900 p-2">
              {link.title}
              </div>
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
