import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main className="flex-1 w-full px-0 mx-auto">
        {children}
      </main>

      {/* <footer
        className="bg-white fw-footer"
        style={{
          backgroundColor: "rgba(11,34,57)",
        }}
      >
        <div className="container mx-auto px-6">
          <div className="mt-6 mb-6 border-b border-teal-800 flex flex-col items-center">
            <div className="sm:w-2/3 text-center py-6">
              <p className="text-sm text-teal-100 mb-2">© 2020 falkonWings</p>
            </div>
          </div>
        </div>
      </footer> */}

      <div>
        {/* Footer */}
        <footer className="footer text-center">
              {/* Footer Social Icons */}
              <div className="">
                <h1 className="uppercase mb-4 font-thin">Connect with Us</h1>
                <div className="flex justify-items-center justify-center">
                  <div style={{width: '65px', height: '65px', margin: '0 35px 0 0'}}>
                    <a aria-label="falkonWings Twitter" href="https://twitter.com/falkonwings">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 112.197 112.197" style={{enableBackground: 'new 0 0 112.197 112.197'}} xmlSpace="preserve">
                        <g>
                          <circle style={{fill: 'rgba(224,224,224)'}} cx="56.099" cy="56.098" r="56.098" />
                          <g>
                            <path style={{fill: '#2c3e50'}} d="M90.461,40.316c-2.404,1.066-4.99,1.787-7.702,2.109c2.769-1.659,4.894-4.284,5.897-7.417
                                    c-2.591,1.537-5.462,2.652-8.515,3.253c-2.446-2.605-5.931-4.233-9.79-4.233c-7.404,0-13.409,6.005-13.409,13.409
                                    c0,1.051,0.119,2.074,0.349,3.056c-11.144-0.559-21.025-5.897-27.639-14.012c-1.154,1.98-1.816,4.285-1.816,6.742
                                    c0,4.651,2.369,8.757,5.965,11.161c-2.197-0.069-4.266-0.672-6.073-1.679c-0.001,0.057-0.001,0.114-0.001,0.17
                                    c0,6.497,4.624,11.916,10.757,13.147c-1.124,0.308-2.311,0.471-3.532,0.471c-0.866,0-1.705-0.083-2.523-0.239
                                    c1.706,5.326,6.657,9.203,12.526,9.312c-4.59,3.597-10.371,5.74-16.655,5.74c-1.08,0-2.15-0.063-3.197-0.188
                                    c5.931,3.806,12.981,6.025,20.553,6.025c24.664,0,38.152-20.432,38.152-38.153c0-0.581-0.013-1.16-0.039-1.734
                                    C86.391,45.366,88.664,43.005,90.461,40.316L90.461,40.316z" />
                          </g>
                        </g>
                      </svg>
                    </a>  
                  </div>
                  <div style={{width: '65px', height: '65px', margin: '0 15px'}}>
                    <a aria-label="falkonWings Face Book" href="https://www.facebook.com/FalkonWings-106114444738584">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 112.196 112.196" style={{enableBackground: 'new 0 0 112.196 112.196'}} xmlSpace="preserve">
                        <g>
                          <circle style={{fill: 'rgba(224,224,224)'}} cx="56.098" cy="56.098" r="56.098" />
                          <path style={{fill: '#2c3e50'}} d="M70.201,58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34
		c0-5.964,2.833-15.303,15.301-15.303L71.56,21.81v12.51h-8.151c-1.337,0-3.217,0.668-3.217,3.513v7.585h11.334L70.201,58.294z" />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
          </div>
        </footer>
        {/* Copyright Section */}
        <section className="copyright py-4 text-center text-white">
          <div className="font-thin">
            <small>Copyright © falkonWings 2020</small>
          </div>
        </section>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
